import { Spin } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import { getSuperStatistic } from "../../../../services/overView"
import {
  beginningOfMonth,
  endOfMonth,
  formatNumberJP,
} from "../../../../customUtil/global"
import Chart1 from "./Chart1"
function sumArray(mang) {
  let sum = 0
  for (let i = 0; i < mang.length; i++) {
    sum += mang[i]
  }

  return sum
}
const ChartProduct = ({ valueFilter }) => {
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [product, setProduct] = useState(null)

  const fetch = useCallback(async () => {
    setIsLoadingData(true)
    const { response } = await getSuperStatistic({
      type: "product",
      startTime:
        valueFilter?.typeFilter == "month"
          ? beginningOfMonth(valueFilter?.dateFilter[0])
          : new Date(valueFilter?.dateFilter[0]),
      endTime:
        valueFilter?.typeFilter == "month"
          ? endOfMonth(valueFilter?.dateFilter[1])
          : new Date(valueFilter?.dateFilter[1]),
      store_id: valueFilter.store_id,
      setBy: valueFilter?.typeFilter,
    })
    console.log("responseresponseresponse", response)
    if (response.status == 200) {
      const data1 = response.data?.data?.data?.map((item) => {
        const objectItem = {
          nameField: "pv",
          xField: item.date,
          yField: item.sum,
        }
        return objectItem
      })
      const data2 = response.data?.data?.dataPastYear?.map((item) => {
        const objectItem = {
          nameField: "前年同月",
          xField: item.date,
          yField: item.sum,
        }
        return objectItem
      })
      setProduct(data2 ? [...data1, ...data2] : [...data1])
      setIsLoadingData(false)
    }
  }, [valueFilter])

  useEffect(() => {
    fetch()
  }, [fetch, valueFilter])

  return (
    <div>
      <div className="inline-block mb-3 ">
        <span className="text-primary-90 ml-[20px] text-[24px]">
          PV（商品の閲覧数）
        </span>
        <span
          style={{
            background: "#ECB427",
            fontFamily: "Noto Sans",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineheight: "21px",
            color: "#FFFFFF",
            marginLeft: "50px",
            padding: "5px 8px 5px 8px",
            position: "relative",
            top: "-4px",
          }}
        >
          PV数
        </span>
        <span
          style={{
            width: "16px",
            height: "20px",
            fontfamily: "Noto Sans",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "21px",
            color: "#ECB427",
            marginLeft: "10px",
          }}
        ></span>
        {product ? (
          <span
            style={{
              width: "110px",
              height: "20px",
              fontfamily: "Noto Sans",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "21px",
              color: "#ECB427",
              marginLeft: "5px",
              position: "relative",
              top: "3px",
            }}
          >
            {formatNumberJP(
              sumArray(
                product
                  ?.filter((el) => (el.name = "出品数"))
                  ?.map((ele) => ele.yField)
              )
            )}
          </span>
        ) : (
          <Spin spinning />
        )}
        <span
          style={{
            fontFamily: "Noto Sans",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "100%",
            color: "#222222",
            marginLeft: "10px",
            position: "relative",
            top: "1px",
          }}
        >
          PV
        </span>
      </div>
      <Spin spinning={isLoadingData}>
        <div style={{ minHeight: 300 }}>
          {product && <Chart1 data={product} />}
        </div>
      </Spin>
    </div>
  )
}

export default ChartProduct

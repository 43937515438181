import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import SuperOverView from "../../domain/SuperOverView"
import "./style.scss"

const index = () => {
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout selectedPage={<SuperOverView />} />
    </div>
  )
}

export default index

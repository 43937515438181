import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";

const Chart1 = ({ data }) => {
  console.log("dataaaa", data);
  const config = {
    data,
    isGroup: true,
    xField: "xField",
    yField: "yField",
    seriesField: "nameField",
    // maxColumnWidth: 100,
    // dodgePadding: 0,
    /** 设置颜色 */
    color: ["#EBF0AC", "#D0C9F7"],
    yAxis: {
      label: {
        formatter: (value) => `${value}万円`,
      },
    },
    /** 设置间距 */
    // marginRatio: 0.1,

    slider:
      data?.length > 10
        ? {
            start: 0,
            end:
              data?.length > 100
                ? 0.2
                : data?.length < 100 && data?.length >= 50
                ? 0.3
                : 0.5,
          }
        : false,
  };
  return (
    <div>
      {data?.length > 0 && <Column {...config} />}
      {data?.length == 0 && <div className="text-center">{"データなし。"}</div>}
    </div>
  );
};

export default React.memo(Chart1);
